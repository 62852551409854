<template>
	<div class="auth-callback">
		<div class="loading"></div>
	</div>
</template>

<script>
export default {
	async mounted() {
		if (this.$route.query && this.$route.query.id_token) {
			const tokenQuery = Object.entries(this.$route.query)
				.reduce((acc, [key, value]) => (acc += `${key}=${value}&`), "")
				.slice(0, -1);

			const auth0User = this.parseJwt(this.$route.query.id_token);

			if (!auth0User.email_verified) {
				this.$router.push({
					name: "AuthEmailVerification",
					params: {
						userEmail: auth0User.email,
					},
				});
			} else {
				try {
					const response = await fetch(
						this.$config.STRAPI_HOST + "/api/auth/auth0/callback?" + tokenQuery
					);
					const { jwt } = await response.json();

					this.$store.commit("setToken", jwt);
					const user = await this.$store.dispatch('fetchMe');
					if (!user.auth0id) {
						this.$store.dispatch('updateUser', {
							id: user.id,
							auth0id: auth0User.sub
						});
					}
					user.auth0id = auth0User.sub;
					this.$store.commit("setUser", user);
					
					this.$router.push("/");
				} catch (e) {
					this.$store.dispatch('logout');
				}
			}
		} else {
			window.location.href = this.$config.STRAPI_HOST + "/api/connect/auth0";
		}
	},
	methods: {
		parseJwt(token) {
			const base64Url = token.split(".")[1];
			const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			const jsonPayload = decodeURIComponent(
				atob(base64)
					.split("")
					.map(function (c) {
						return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join("")
			);

			return JSON.parse(jsonPayload);
		},
	},
};
</script>

<style lang="sass" scoped>
.auth-callback
	.loading
		display: block
		width: 128px
		height: 128px
		background: url('../assets/images/dashboard/icons/autosave.gif') no-repeat center
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
</style>
